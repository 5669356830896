import { ReactElement, useEffect, useState } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { useAuth, isClinician, isTechnician, isAdmin, isSuperAdmin, isMCU, User } from "./context/useAuth";

// pages
import Login from "./pages/Login";
import ClinicianHome from "./pages/ClinicianHome";
import AddStudies from "./pages/AddStudy";
import Study from "./pages/Study";
import Reports from "./pages/Reports";
import ReportView from "./pages/ReportView";
import ReportReview from "./pages/ReportReview";
import Region from "./pages/Region";
import Clinic from "./pages/Clinic";
import AdminSearch from "./pages/AdminSearch";
import AddEditAccount from "./pages/AddEditAccount";
import { PortalSettings } from "./pages/PortalSettings";
import { AdminStudy } from "./pages/AdminStudy";
import Regions from "./pages/Regions";
import Cluster from "./pages/Cluster";
import AdminReportReview from "./pages/AdminReportReview";
import Accounts from "./pages/Accounts";
import { DMSTestPage } from "./pages/DMSTest";
import MCUHome from "./pages/MCUHome";
import { usePermissions, PermissionsE } from "./context/usePermissions";
import {SpecificationsPage} from "./pages/Specifications";
// import { isRefreshing } from "./api/networkLayer2";
// import { v4 } from 'uuid';

interface ProtectedRouteInterfaces {
  isAllowed: boolean;
  redirectPath?: string;
  children?: ReactElement;
}

export const baseUrls = {
  login: '/',
  studies: '/studies',
  studiesAdd: '/studies/add',
  study: '/study',
  studyPage: '/study/:studyId',
  reports: '/reports',
  reportView: '/reports/view',
  reportReview: '/report/review',

  adminFinalReportReviewBase: '/report-final/review',
  adminFinalReportReview: '/report-final/review/:studyId',

  regions: '/regions',
  region: '/regions/:regionId',

  clusters: '/clusters',
  cluster: '/clusters/:clusterId',

  clinics: '/clinics',
  clinic: '/clinics/:clinicId',
  // clinicAddAccount :  '/clinics/:clinicId/add',
  // clinicsEditAccount :  '/clinics/:clinicId/edit/:staffProfileId',


  // clientsAdd: '/clients/add',
  // clientsAccount: '/clients/:clientId',

  // clientsEditAccount :  '/clients/:clientId/edit/:clientAccountID',
  clientsTools: "/clients/tools",
  clientsToolsStudyBase: "/clients/tools/study",
  clientsToolsStudy: "/clients/tools/study/:idStudy",
  portalSettings: "/clients/settings",
  accounts: "/accounts",
  addAccount: "/add-account",
  editAccount: "/edit-account",
  mcuHome: "/mcu-home"
}

export const ProtectedRoute = ({ isAllowed, redirectPath = baseUrls.login, children }: ProtectedRouteInterfaces) => {
  if (!isAllowed) {
    // user is not authenticated
    return <Navigate to={redirectPath} replace />;
  }
  return children ? children : <Outlet />;
};

export const homePageUrl = (user: User | null) => {
  if (!user) return baseUrls.login;
  if (!!isClinician(user)) return baseUrls.studies;
  if (!!isMCU(user)) return baseUrls.mcuHome;
  if (!isClinician(user)) return baseUrls.regions;

  return baseUrls.login;
}

export const AppRoutes = () => {
  const { user } = useAuth();
  const { hasPermission } = usePermissions();

  /*
  const [key, setKey] = useState(v4());
  useEffect(() => {
    setKey(v4());
  }, [isRefreshing])
  */

  const accountPage = hasPermission!(PermissionsE["tab.accounts"]) ?
    <Route path={baseUrls.accounts} element={<Accounts />} />
    : <></>;

  const settingsPage = hasPermission!(PermissionsE["tab.settings"]) ?
    <Route path={baseUrls.portalSettings} element={<PortalSettings />} />
    : <></>;

  const reviewPage = hasPermission!(PermissionsE["tab.review"]) ?
    <Route path={baseUrls.adminFinalReportReview} element={<AdminReportReview />} />
    : <></>;

  const addStudy = hasPermission!(PermissionsE["study.w"]) ?
    <Route path={baseUrls.studiesAdd} element={<AddStudies />} />
    : <></>;

  const mcuHomePage = !!isMCU(user) ?
    <Route path={baseUrls.mcuHome} element={<MCUHome />} />
    : <></>;

  return (
    <Routes>
      <Route index element={<Login />} />
      <Route element={<ProtectedRoute isAllowed={!!isClinician(user)} />}>
        <Route path={baseUrls.studies} element={<ClinicianHome />} />
        {addStudy}
        <Route path={baseUrls.studyPage} element={<Study />} />
      </Route>
      {/*<Route element={<ProtectedRoute isAllowed={!!isTechnician(user)} />}>*/}
      {/*  <Route path={baseUrls.reports} element={<Reports />} />*/}
      {/*  <Route path={baseUrls.reportView} element={<ReportView />} />*/}
      {/*  <Route path={baseUrls.reportReview} element={<ReportReview />} />*/}
      {/*</Route>*/}
      <Route element={<ProtectedRoute isAllowed={!isClinician(user)} />}>
        {mcuHomePage}
        {accountPage}
        <Route path={baseUrls.regions} element={<Regions />} />
        <Route path={baseUrls.region} element={<Region />} />
        <Route path={baseUrls.cluster} element={<Cluster />} />
        <Route path={baseUrls.clinic} element={<Clinic />} />
        {reviewPage}
        <Route path={baseUrls.addAccount} element={<AddEditAccount />} />
        <Route path={baseUrls.editAccount} element={<AddEditAccount />} />
        {/*<Route path={baseUrls.clientsAccount} element={<Clinic />}/>*/}
        <Route path={baseUrls.clientsTools} element={<AdminSearch />} />
        {settingsPage}
        <Route path={baseUrls.clientsToolsStudy} element={<AdminStudy />} />
      </Route>
      <Route
        path="*"
        element={<Navigate to="/" replace />}
      />
      <Route path="/dms-test" element={<DMSTestPage />} />
      <Route path="/specifications" element={<SpecificationsPage/>} />
    </Routes>
  )
};